import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import GreenLogo from '../images/loader-green.png';
import WhiteLogo from '../images/loader-white.png';

const logos = {
  "green": GreenLogo,
  "white": WhiteLogo
}

const Loader = ({color = "green"}) => {

  /* If animate is false, then previous animations have completed, so there should be no delay */
  /* otherwise, delay render to after previous animations have completed */
  // const delay = props.animate ?
  //   props.delay
  //   : 0


  return(
    <AnimatePresence>
      <motion.div
        initial={{opacity: 0, scale: 0.8}} /* Animation start point */
        animate={{opacity: 1, scale: 1}} /* Animation end point */
        exit={{opacity: 0, scale: 0.8}}
        transition={{ type: "spring", duration: 2, bounce: 0.6}} /* Add bounciness to animation transition */
        className={"leaf-loader"}
        >
        <motion.img
          src={logos[color]}
          initial={{opacity: 0.7, scale: 0.88}} /* Animation start point */
          animate={{opacity: 1, scale: 1}} /* Animation end point */
          transition={{  repeat: Infinity, duration: 1, repeatType: "reverse"}}
          alt="Leaf logo loader"
          />
      </motion.div>
  </AnimatePresence>
  );
}

export default Loader;
