import { HttpClient } from "./http_client"
import { paginationConst } from "../js/constants"

const treesEndpoint = "/api/trees" 
const publicTreesEndpoint = "/api/user-trees" 

export async function getTree(id) {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT)
  return await httpClient.get(treesEndpoint + "/" + id)
}

export async function getTrees() {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT)
  return await httpClient.get(treesEndpoint)
}

export async function getTreeOfMonth() {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT)
  return await httpClient.get('/api/tree-of-month')
}

export async function getPublicUserTrees(pagination) {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT)
  return await httpClient.get(publicTreesEndpoint + paginationConst.paginationQuery(pagination))
}

export async function getPublicUserTree(id) {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT)
  return await httpClient.get(publicTreesEndpoint + '/' + id)
}