const userTreesReducer = (state = false, action) => {

  if (action.type === 'SET_PUBLIC_USER_TREES') {
    return action.payload;
  }

  return state;
}

export default userTreesReducer;
