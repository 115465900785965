const treesObjectReducer = (state = false, action) => {
  if (action.type === 'SET_TREES_OBJECT') {
    const trees = action.payload;
    const treesObject = {};

    for(let tree of trees) {
      treesObject[tree.id] = tree;
    }

    return treesObject;
  } 
    
  return state;
  
}

export default treesObjectReducer;
