const treeOfMonthReducer = (state = false, action) => {

  if (action.type === 'SET_TREE_OF_MONTH') {
    return action.payload;
  }

  return state;
}

export default treeOfMonthReducer;
