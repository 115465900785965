import React, { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from "react-redux";
import { setLang } from '../../actions';

import { useLocation, useHistory } from 'react-router-dom';
import { cookiesConst } from '../../js/constants';
import { EN } from '../../constants/common';


function LanguageToggle(props) {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['selected-language']);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const lang = useSelector(state => state.lang) || EN;
  const [checked, setChecked] = useState(lang !== EN);
  const handleToggledChanged = (e) => {
    if (checked) {
      handleChangeLanguage('en');
    } else {
      handleChangeLanguage('es');
    }
  }

  const handleChangeLanguage = (language = null) => {
    setChecked(language === 'es');
    i18n.changeLanguage(language);
    dispatch(setLang(language));
    const currentUri = location.pathname.split('/');
    let newUri = '';
    currentUri.splice(0, 2)
    newUri = `/${language}/` + currentUri.join('/');

    history.push(newUri);
    setCookie('selected-language', language, { path: '/', expires: cookiesConst.langExpires });
  }

  return (
    <Suspense fallback={<div>Loading languages...</div>}>
      <label className={"language-toggle"}>
        <input
          checked={checked}
          type={"checkbox"}
          onChange={handleToggledChanged} />
        <span className={"toggle-slider round"}>
          <span className={"absolute w-100 flex space-between"}>
            <p className={"toggle-language text-darkgreen"}>ES</p>
            <p className={"toggle-language text-white"}>EN</p>
          </span>
        </span>
      </label>
    </Suspense>
  );
}

export default LanguageToggle;
