import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory
} from "react-router-dom";

import { clearCart, rememberUserData } from '../actions';

const checkExpire = (expires_at) => {
  const now = new Date();
  const expire = new Date(expires_at);
  return now < expire;
}

const Session = ({ children }) => {
  const userData  = useSelector(state => state.userData);
  const lang  = useSelector(state => state.lang);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(
      clearCart()
    )
    dispatch(
      rememberUserData(false)
    );
    history.push(`/${lang}/login`);
  }

  // Best solution is to return a jwt and check for expiration
  useEffect(async () => {
    if (userData && !checkExpire(userData.expires_at)) {
       handleLogout();
    }
  }, [userData]);

  return children;
}

export default Session;