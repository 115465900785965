const rememberUserDataReducer = (state = false, action) => {
  if (state === undefined) {
    return null;
  }

  switch (action.type) {

    case 'REMEMBER_USER_DATA': {
      return action.payload;
    }

    case 'UPDATE_USER_DATA': {
      return {...state, ...action.payload};
    }

    default:
      return state
  }
}

export default rememberUserDataReducer;
