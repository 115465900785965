import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useHistory
} from "react-router-dom";

import { setLang, setIpCountryCode } from '../actions';

import Loader from '../components/loader';

import { SUPPORT_LANGUAGES, DEFAULT_LANGUAGE, DEFAULT_COUNTRY } from '../constants/common';

const defaultLanguage = (languages) => (languages.split('-')[0]);

const Language = ({ children }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(['cookie-policy-accepted', 'selected-language']);

  const [loading, setLoading] = useState(true);

  const langCookie = cookies['selected-language'];

  const setLanguage = async (language, addToRoute) => {
    fetch('https://ipapi.co/json/') // This needs to be change to a variable
      .then(response => response.json())
      .then(data => {
        const currentLang = language || defaultLanguage(data?.languages) || DEFAULT_LANGUAGE;
        dispatch(setIpCountryCode(data?.country_code || DEFAULT_COUNTRY));
        dispatch(setLang(currentLang));
        return currentLang;
      })
      .catch((err) => {
        // Not setting the cookie falling back to default language and country
        const currentLang = language || DEFAULT_LANGUAGE;
        dispatch(setIpCountryCode(DEFAULT_COUNTRY));
        dispatch(setLang(currentLang));
        console.log('Error getting the country code', err);
        return currentLang;
      }).then((lang) => {
        if (addToRoute) {
          history.push(lang + '/' + location.search);
        }
        i18n.changeLanguage(lang);
        setCookie('selected-language', lang, { path: '/' });
        setLoading(false);
      });
  }

  // Logic to be implemented
  // Check for the current language in path
  // If the language is set and is valid, set the language to the cookie value
  // If the language is not set or is invalid, 
  // If the cookie is set add the language to the path
  // If the cookie is not set add the default language to the path
  // After set the language. call the API to get the country code and set the language and the cookie
  // If the cookie is not set, call the API to get the country code and set the language. 
  useEffect(() => {
    const [, langInUrl] = location.pathname.split('/');
    let currentLang;
    let addLangToRoute = false;
    if (langInUrl && SUPPORT_LANGUAGES.includes(langInUrl)) {
      currentLang = langInUrl;
    } else {
      addLangToRoute = !langInUrl;
      currentLang = langCookie || currentLang;
    }
    setLanguage(currentLang, addLangToRoute);
  }, []);

  if (loading) {
    return <div id="preloader"><Loader /></div>;
  }

  return children;
}

export default Language;