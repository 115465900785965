import React from 'react';

function SideMenuToggle(props) {
  return(
    <button className={"side-menu-toggle"} onClick={(e) => {e.preventDefault(); props.click();}} onTouchEnd={(e) => {e.preventDefault(); props.click();}}>
      <div className={`menu-toggle-line bg-${props.color}`}></div>
      {/* If props.halfline is set, then transition to the half line size */}
      <div className={`menu-toggle-line bg-${props.color} ${props.halfLine && "half-line"}`}></div>
    </button>
  );
}

export default SideMenuToggle;
