import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { motion } from 'framer-motion';

import logoWhite from '../../images/logo-white.png'
import logoGreen from '../../images/logo-green.png'

import treeButton from '../../images/tree-button.png'
import cartButton from '../../images/cart-button.png'
import faqButton from '../../images/faq-button.png'

import LanguageToggle from './language_toggle'
import SideMenuToggle from './side_menu_toggle'
import HeaderButton from './header_button'

import SideMenu from './side_menu'
import LeafLink from './leaf_link';


const Header = (props) => {
  const { t } = useTranslation(['components/nav']);
  let location = useLocation()

  const treesInCart = useSelector(state => state.treesInCart);
  const forestsInCart = useSelector(state => state.forestsInCart);
  const subscriptionsInCart = useSelector(state => state.subscriptionsInCart);

  const [totalCount, setTotalCount] = useState(0);
  const [secondaryTotalCount, setSecondaryTotalCount] = useState(0); //For the purposes of retirggering animation on count badge - briefly unmounts badge, and then remounts after re-render, thus triggering animation
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [halfToggleLine, setHalfToggleLine] = useState(false)
  const ES = "es";

  /* Opens side menu, if toggle button is clicked or tapped */
  /* Sets bottom toggle line to animate to half-width */
  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
    setHalfToggleLine(!halfToggleLine);
  }

  useEffect(() => {
    setSideMenuOpen(false);
    setHalfToggleLine(false);
  }, [location]);

  useEffect(() => {
    if (treesInCart || forestsInCart || subscriptionsInCart) {
      const treesCount = Object.keys(treesInCart).reduce( (a , b) => a + treesInCart[b].amount, 0);
      const forestCount = Object.keys(forestsInCart).reduce( (a , b) => a + forestsInCart[b].jungle_size, 0);
      const subscriptionCount = Object.keys(subscriptionsInCart).reduce( (a , b) => a + subscriptionsInCart[b].amount, 0);

      setTotalCount( treesCount + forestCount + subscriptionCount );
    }

  }, [treesInCart, forestsInCart, subscriptionsInCart]);

  //This useEffect updates secondaryTotalCount to re-render the tree count badge
  useEffect(() => {
    setSecondaryTotalCount(totalCount)
  }, [totalCount]);

  const fontOverride = props?.lang === ES ? "es-text " : "";
  return(
    <div className={"header-bar w-100"}>
      <LeafLink  to="/">
        <div ref={props.navRef} className={"header-logo-container mt-10 ml-10"}>
          {/* Overlay two logos - white one and green one */}
          {/* Transition opacity between two when scroll to white background*/}
          <img src={logoWhite} alt="Panama Wildlife Logo - Adopt a tree" className={`header-logo white-logo ${props.navColorClass !== "white" && "transparent"}`}></img>
          <img src={logoGreen} alt="Panama Wildlife Logo - Adopt a tree" className={`header-logo green-logo ${props.navColorClass !== "forest" && "transparent"}`}></img>
        </div>
      </LeafLink>

      <HeaderButton
        icon={treeButton}
        text={t("components/nav:store")}
        alt={"Leaf shaped icon for the Native Store button"}
        link={"/native-store"}
        visibility={"visible-small mt-20"}
        className={"header-button-store"}
        textClassName={fontOverride}
      />

      <div className={"relative visible-small mt-20"}>
        <HeaderButton icon={cartButton} text={t("components/nav:cart")} alt={"Basket shaped icon for the View Cart button"} link={"/cart"} className={"header-button-cart"} textClassName={fontOverride}/>
        {/*Condition below briefly unmounts badge while totalCount and secondaryTotalCount are not equal - the useEffect above then immediately makes them equal, thus component is unmounted for one frame and allows it to animate */}
        { treesInCart && totalCount === secondaryTotalCount && totalCount > 0 &&
          <motion.div
            initial={{scale: 0}} /* Animation start point */
            animate={{scale: 1, transition:{ type: "spring", stiffness: 300}}} /* Animation end point */
            className={"cart-count"}>
            {totalCount}
          </motion.div>
        }
      </div>

      <HeaderButton
        icon={faqButton}
        text={t("components/nav:faqs")}
        alt={"Text bubble shaped icon for the Frequently Asked Questions button"}
        link={"/faqs"}
        visibility={"visible-small mt-20"}
        className={"header-button-faqs"}
      />

      <div className={"mt-20 mr-20 flex"}>

        <div className={"mr-20 visible-landscape-up"}>
          <LanguageToggle/>
        </div>

        <div className={"flex column"}>
          <SideMenuToggle color={props.navColorClass} halfLine={halfToggleLine} click={toggleSideMenu}/>
          <div className={"flex column visible-landscape-up"}>
            <HeaderButton icon={treeButton} text={t("components/nav:store")} alt={"Leaf shaped icon for the Native Store button"} link={"/native-store"} className={"header-button-store"} textClassName={fontOverride}/>
            <div className={"relative"}>
              <HeaderButton icon={cartButton} text={t("components/nav:cart")} alt={"Basket shaped icon for the View Cart button"} link={"/cart"} className={"header-button-cart"} textClassName={fontOverride}/>
              {/*Condition below briefly unmounts badge while totalCount and secondaryTotalCount are not equal - the useEffect above then immediately makes them equal, thus component is unmounted for one frame */}
              { treesInCart && totalCount === secondaryTotalCount && totalCount > 0 &&
                <motion.div
                  initial={{scale: 0}} /* Animation start point */
                  animate={{scale: 1, transition:{ type: "spring", stiffness: 300}}} /* Animation end point */
                  className={"cart-count"}>
                  {totalCount}
                </motion.div>
              }
            </div>
            <HeaderButton icon={faqButton} text={t("components/nav:faqs")} alt={"Text bubble shaped icon for the Frequently Asked Questions button"} link={"/faqs"} className={"header-button-faqs"} />
          </div>
          </div>
      </div>
      <SideMenu open={sideMenuOpen}/>
    </div>
  );
}

export default Header;
