import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";

import 'focus-visible';

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { loadState, saveState } from './js/local_storage.js';

import addTreeToCartReducer from './reducers/add_tree_to_cart_reducer';
import addForestToCartReducer from './reducers/add_forest_to_cart_reducer';
import addSubscriptionToCartReducer from './reducers/add_subscription_to_cart_reducer';
import setPopupOpenReducer from './reducers/set_popup_open_reducer';
import setMiniPopupOpenReducer from './reducers/set_mini_popup_open_reducer';
import rememberUserDataReducer from './reducers/remember_user_data_reducer';
import setLangReducer from './reducers/set_lang_reducer';
import wildlifeReducer from './reducers/wildlife_reducer';
import treesReducer from './reducers/trees_reducer';
import treeOfMonthReducer from './reducers/tree_of_month_reducer';
import userTreesReducer from './reducers/user_trees_reducer';
import treesObjectReducer from './reducers/trees_object_reducer';
import ipCountryCodeReducer from './reducers/ip_country_code_reducer.js';
import Loader from './components/loader';
import Language from './hooks/use_language';
import Session from './hooks/use_session';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG
}

TagManager.initialize(tagManagerArgs);

const preloadedState = loadState();

const store = configureStore({
  reducer: {
    wildlife: wildlifeReducer,
    trees: treesReducer,
    treesObject: treesObjectReducer,
    treeOfMonth: treeOfMonthReducer,
    treesInCart: addTreeToCartReducer,
    publicUserTrees: userTreesReducer,
    forestsInCart: addForestToCartReducer,
    subscriptionsInCart: addSubscriptionToCartReducer,
    popupOpen: setPopupOpenReducer,
    miniPopupOpen: setMiniPopupOpenReducer,
    userData: rememberUserDataReducer,
    lang: setLangReducer,
    ipCountryCode: ipCountryCodeReducer
  },
  preloadedState
});

store.subscribe(() => {
  saveState({
    wildlife: store.getState().wildlife,
    trees: store.getState().trees,
    treesInCart: store.getState().treesInCart,
    forestsInCart: store.getState().forestsInCart,
    subscriptionsInCart: store.getState().subscriptionsInCart,
    userData: store.getState().userData,
    lang: store.getState().lang,
  });
});


ReactDOM.render(
  <Suspense fallback={<div id="preloader"><Loader /></div>}>
    <CookiesProvider>
      <Provider store={store}>
        <Router>
          <Language>
            <Session>
              <App />
            </Session>
          </Language>
        </Router>
      </Provider>
    </CookiesProvider>
  </Suspense>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();