const EN = 'en';
const ES = 'es';
const DEFAULT_COUNTRY = 'pa';
const SUPPORT_LANGUAGES = [EN, ES];
const DEFAULT_LANGUAGE = EN;

export {
  SUPPORT_LANGUAGES,
  DEFAULT_LANGUAGE,
  DEFAULT_COUNTRY,
  EN,
  ES
};