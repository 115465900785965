const setPopupOpenReducer = (state = false, action) => {
  if(state === undefined) {
    return null;
  }

  if (action.type === 'SET_POPUP_OPEN') {
    return action.payload;
  } else {
    return state;
  }
}

export default setPopupOpenReducer;
