const addTreeToCartReducer = (state = {}, action) => {
  if(state === undefined) {
    return null;
  }

  switch (action.type) {
    case 'ADD_TREE_TO_CART': {
      let key = Object.keys(action.payload)[0]

      if (key === undefined || !key) {
        return state
      }
  
      if (action.payload[key].amount === 0) {
        return state
      }
      return {...state, ...action.payload};
    }

    case 'REMOVE_TREE_FROM_CART': {
      let key = Object.keys(action.payload)[0]

      if (action.payload[key].amount === 0 && key in state) {
        const newState = {...state};
        delete newState[key];
  
        return newState;
      }  
    }

    case 'CLEAR_CART': {
      return {};
    }

    default:
      return state

  }
}

export default addTreeToCartReducer;
