import React from 'react';
import { motion } from "framer-motion";
import LeafLink from './leaf_link';

function HeaderButton({ link, visibility, icon, alt, className, textClassName = "", text }) {
  return(
    <LeafLink  to={link} className={visibility}>
      <motion.div
        className={"header-button flex column align-center justify-center"}
        whileTap={{ scale: 0.92 }} /* Set a scale animation on tap with whileTap */
        transition={{ type: "spring", duration: 0.7, bounce: 0.7}} /* Add bounciness to animation transition */
        >
          <img src={icon} alt={alt} className={`${className} header-button-icon`}/>
          {/* text-forest className sets the text color to the middle green (i.e. not dark green or light green) */}
          <p className={`${textClassName}text-forest bg-white radius-8`}>{text}</p>
      </motion.div>
    </LeafLink>
  );
}

export default HeaderButton;
