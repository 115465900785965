import { HttpClient } from "./http_client";

const wildlifeEndpoint = "/api/wildlife";

export async function getAnimal(id) {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT);
  return await httpClient.get(wildlifeEndpoint + "/" + id);
}

export async function getAnimals() {
  const httpClient = new HttpClient(process.env.REACT_APP_API_ENDPOINT);
  return await httpClient.get(wildlifeEndpoint);
}
