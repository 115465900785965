import { useEffect } from "react";
import { useParams } from "react-router-dom"

function Confirm3ds() {

  let { type } = useParams();
  useEffect(() => {
    type = type ? `-${type}` : '';
    window.top.postMessage(`3DS-authentication-complete${type}`);
  },[]);
  return (<div></div>);
}

export default Confirm3ds;

