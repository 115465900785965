export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    let state = JSON.parse(serializedState);
    state = checkUserExpired(state);
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.error(`Failed to save to local storage. Error: ${err}`)
  }
};

const checkUserExpired = (state) => {
  if (Date.now() > new Date(state.userData.expires_at)) {
    state.userData = false;
  }
  return state;
}
