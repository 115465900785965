import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";

import './App.scss';

import { useCookies } from 'react-cookie';
import { AnimatePresence } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { useWindowScroll, useMedia } from 'react-use';
import { useSelector, useDispatch } from "react-redux";

import { getTrees, getTreeOfMonth, getPublicUserTrees } from './services/trees_service';
import { getAnimals } from './services/wildlife_service';

import PrivateRoute from "./components/router/private_route";
import PublicOnlyRoute from "./components/router/public_only_route";

import { setWildlife, setTrees, setTreesObject, setTreeOfMonth, setPublicUserTrees } from './actions';

import Header from './components/navigation/header';
import Loader from './components/loader';
import Confirm3ds from './pages/cart_page/confirm_3ds';

// Pages with lazy loading
const LandingPage = lazy(() => import('./pages/landing_page/landing_page'));
const AboutUsPage = lazy(() => import('./pages/about_us_page/about_us_page'));
const OurTreesPage = lazy(() => import('./pages/our_trees_page/our_trees_page'));
const TreeDatabasePage = lazy(() => import('./pages/tree_database_page/tree_database_page'));
const WildlifePage = lazy(() => import('./pages/wildlife_page/wildlife_page'));
const NativeStorePage = lazy(() => import('./pages/native_store_page/native_store_page'));
const TreeProfilePage = lazy(() => import('./pages/tree_profile_page/tree_profile_page'));
const WildlifeProfilePage = lazy(() => import('./pages/wildlife_profile_page/wildlife_profile_page'));
const LocationProfilePage = lazy(() => import('./pages/location_profile_page/location_profile_page'));
const OurProjectsPage = lazy(() => import('./pages/our_projects_page/our_projects_page'));
const LoginPage = lazy(() => import('./pages/login_register_page/login_page'));
const RegisterPage = lazy(() => import('./pages/login_register_page/register_page'));
const ThankYouPage = lazy(() => import('./pages/login_register_page/thank_you_page'));
const FAQPage = lazy(() => import('./pages/faq_page/faq_page'));
const CartPage = lazy(() => import('./pages/cart_page/cart_page'));

const UserProfilePage = lazy(() => import('./pages/user_profile_page/user_profile_page'));

const PrivacyPolicyPage = lazy(() => import('./pages/terms_and_policies_pages/privacy_policy_page'));
const CookiePolicyPage = lazy(() => import('./pages/terms_and_policies_pages/cookie_policy_page'));
const ContactPage = lazy(() => import('./pages/contact_page/contact_page'));
const TreeSelectedPage = lazy(() => import('./pages/user_tree_page/tree_selected'));
const Show404Page = lazy(() => import('./pages/error_pages/404_page'));
const CookiePolicy = lazy(() => import('./components/cookie_policy'));
const ForgotPasswordPage = lazy(() => import('./pages/login_register_page/forgot_password_page'));
const ResetPasswordPage = lazy(() => import('./pages/login_register_page/reset_password_page'));
const UserOrderPage = lazy(() => import('./pages/user_order_page/user_order_page'));

const Helmet = lazy(() => import('react-helmet'));
const Footer = lazy(() => import('./components/navigation/footer'));

function App() {
  const [localAnimalAPI] = useState(null)
  const [localTreeAPI] = useState(null)
  const [localStatsAPI] = useState(null)
  const [localPurchasesAPI] = useState(null)

  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['cookie-policy-accepted', 'selected-language']);
  const [cookiesClosed, setCookiesClosed] = useState(false);


  const [navColorClass, setNavColorClass] = useState("white");
  const [breakpoints, setBreakpoints] = useState({
    isTabletLandscapeOrSmaller: false,
    isDesktopOrSmaller: false
  })

  const [skipPaths] = useState(['/login', '/register', '/forgot-password'])

  const isTabletLandscapeOrBigger = useMedia('(min-width: 767px)');
  const isTabletLandscapeOrSmaller = useMedia('(max-width: 767px)');
  const isDesktopOrSmaller = useMedia('(max-width: 991px)');
  const isBootstrapLgOrSmaller = useMedia('(max-width: 1199px)');
  const { y } = useWindowScroll();

  let location = useLocation();

  const [lastPath, setLastPath] = useState(['/'])
  const lang = useSelector(state => state.lang);
  const handleIntersection = (color) => {
    setNavColorClass(color)
  }

  const history = useHistory();

  const langCookie = cookies['selected-language'];
  const ipCountryCode = useSelector(state => state.ipCountryCode);

  useEffect(() => {
    getAnimals()
      .then((res) => {
        dispatch(setWildlife(res.data))
      }).catch((error) => {
        console.error('Error:', error);
      });

    getTrees()
      .then((res) => {
        dispatch(setTrees(res.data))
        dispatch(setTreesObject(res.data))
      }).catch((error) => {
        console.error('Error:', error);
      });

    getTreeOfMonth()
      .then((res) => {
        dispatch(setTreeOfMonth(res.data))
      }).catch((error) => {
        console.error('Error:', error);
      });

    getPublicUserTrees()
      .then((res) => {
        dispatch(setPublicUserTrees(res.data))
      }).catch((error) => {
        console.error('Error:', error);
      });
  }, [lang]);

  useEffect(() => {
    setBreakpoints({
      isTabletLandscapeOrSmaller: isTabletLandscapeOrSmaller,
      isDesktopOrSmaller: isDesktopOrSmaller,
      isTabletLandscapeOrBigger: isTabletLandscapeOrBigger,
      isBootstrapLgOrSmaller: isBootstrapLgOrSmaller
    })
  }, [isTabletLandscapeOrSmaller, isDesktopOrSmaller, isTabletLandscapeOrBigger, isBootstrapLgOrSmaller]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!skipPaths.includes(location.pathname)) {
      if (lastPath.length === 0) {
        setLastPath([location.pathname])
      } else if (lastPath.length === 1) {
        setLastPath([...lastPath, location.pathname])
      } else {
        const locations = lastPath
        locations.shift()
        setLastPath([...locations, location.pathname])
      }
    }
  }, [location]);



  return (

    <div>
      {ipCountryCode || langCookie || lang ? (<div>
        <Helmet>
          <link rel="preconnect" href="https://www.googletagmanager.com" />
          <link rel="preconnect" href="https://www.google-analytics.com" />
        </Helmet>
        <Header navColorClass={navColorClass} lang={lang} />
        <ToastContainer
          position="bottom-right"
          theme="colored"
        />
        <AnimatePresence>
          {!cookiesClosed && (!cookies['cookie-policy-accepted'] || cookies['cookie-policy-accepted'] !== "true") &&
            <CookiePolicy click={setCookiesClosed} />
          }
        </AnimatePresence>

        <Switch>
          <Route path={`/${lang}/planted-trees/:tree_id`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <TreeSelectedPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/planted-trees`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <TreeDatabasePage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path="/cookie-policy">
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <CookiePolicyPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/privacy`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <PrivacyPolicyPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <PrivateRoute path={`/${lang}/profile/orders/:order_id`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <UserOrderPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </PrivateRoute>

          <Route path={`/${lang}/cart`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <CartPage
                treeAPI={localTreeAPI}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <PrivateRoute path={`/${lang}/profile/tree/:tree_id`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <TreeSelectedPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute path={`/${lang}/profile`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <UserProfilePage
                scroll={y}
                onIntersect={handleIntersection}
                APIs={{
                }}
              />
            </Suspense>
          </PrivateRoute>

          <Route path={`/${lang}/contact`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <ContactPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/faqs`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <FAQPage
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/locations/:location`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <LocationProfilePage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
                APIs={{
                  trees: localTreeAPI,
                  stats: localStatsAPI,
                  animals: localAnimalAPI,
                  purchases: localPurchasesAPI
                  // parks: locationsAPI()
                }}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/thank-you`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <ThankYouPage
                onIntersect={handleIntersection}
                lastPath={lastPath[0]}
              />
            </Suspense>
          </Route>

          <PublicOnlyRoute path={`/${lang}/register/:token?`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <RegisterPage
                onIntersect={handleIntersection}
                lastPath={lastPath[0]}
              />
            </Suspense>
          </PublicOnlyRoute>

          <PublicOnlyRoute path={`/${lang}/login`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <LoginPage
                onIntersect={handleIntersection}
                lastPath={lastPath[0]}
              />
            </Suspense>
          </PublicOnlyRoute>

          <PublicOnlyRoute path={`/${lang}/forgot-password`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <ForgotPasswordPage
                onIntersect={handleIntersection}
              />
            </Suspense>
          </PublicOnlyRoute>

          <PublicOnlyRoute path={`/${lang}/reset-password`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <ResetPasswordPage
                onIntersect={handleIntersection}
              />
            </Suspense>
          </PublicOnlyRoute>

          <Route path={`/${lang}/our-projects`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <OurProjectsPage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              // parks={locationsAPI()}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/wildlife/:animal`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <WildlifeProfilePage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/trees/:tree`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <TreeProfilePage
                // When going from one tree profile to another, key is used to force refresh
                // Key value is set in card_reverse components
                key={location.key}
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/native-store`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <NativeStorePage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
                APIs={{ trees: localTreeAPI, stats: localStatsAPI, animals: localAnimalAPI, purchases: localPurchasesAPI }}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/wildlife`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <WildlifePage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
                APIs={{ trees: localTreeAPI }}
              />
            </Suspense>
          </Route>

          <Route path={`/confirm3ds/:type?`}>
            <Confirm3ds />
          </Route>

          <Route path={`/${lang}/our-trees`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <OurTreesPage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
                APIs={{ trees: localTreeAPI, animals: localAnimalAPI }}
              />
            </Suspense>
          </Route>

          <Route path={`/${lang}/about`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <AboutUsPage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route exact path={`/${lang}`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <LandingPage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route exact path={`/${lang}/activate/:activationToken`}>
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <LandingPage
                breakpoints={breakpoints}
                scroll={y}
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>

          <Route path="*">
            <Suspense fallback={<div className={"viewport-size bg-white"} />}>
              <Show404Page
                onIntersect={handleIntersection}
              />
            </Suspense>
          </Route>
        </Switch>
        <Suspense fallback={<div />}>
          <Footer />
        </Suspense>
      </div>) :
        (
          <div id="preloader"><Loader /></div>

        )}


    </div>
  );
}

export default App;
