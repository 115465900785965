import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children: Component, path, ...rest }) => {
  const userData = useSelector(state => state.userData);

  return (
    <Route
      path={path}
      {...rest}
    >
      { userData ? (
          Component
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                prevLocation: path,
                error: "You need to login first!"
              }
            }}
          />
        )
      }
    </Route>
  )
};

export default withRouter(PrivateRoute);
