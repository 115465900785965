import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const LeafLink = ({type, to, className, destination, target, clickAction, children}) => {
  const lang = useSelector(state => state.lang);
  let url = to ? to : destination;
  if(url['pathname']) {
    url['pathname'] = `/${lang}${url['pathname']}`;

  } else {
    url = `/${lang}${url}`;
  }
  

  if(type === 'Nav') {
    return (<NavLink to={url} onClick={clickAction ? (e)=>{clickAction(e)} : null} className={className}>{children}</NavLink>)
  } else if(type === 'Hash') {
    return (
      <HashLink
        to={url}
        target={target}
        /* color sets the background color of button - choice from bg-lightgreen and bg-forest */
        className={className}
        onClick={clickAction}
      >
        {children}
      </HashLink>
    )
  } else {
    return (
      <Link to={url} className={className} target={target} onClick={clickAction ? (e) => { clickAction(e) } : null}>
        {children}
      </Link>

    )
  }
}

export default LeafLink;

