import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicOnlyRoute = ({ children: Component, path, ...rest }) => {
  const userData = useSelector(state => state.userData);

  return (
    <Route
      path={path}
      {...rest}
    >
      {!userData ? (
        Component
      ) : (
        <Redirect
          to={{
            pathname: "/profile",
            state: {
              prevLocation: path,
              error: "Already logged in!"
            }
          }}
        />
      )
      }
    </Route>
  )
};

export default withRouter(PublicOnlyRoute);
