export function addTreeToCart(tree) {
  return {
    type: 'ADD_TREE_TO_CART',
    payload: tree,
  };
};

export function removeTreeFromCart(tree) {
  return {
    type: 'REMOVE_TREE_FROM_CART',
    payload: tree,
  };
};

export function clearCart() {
  return {
    type: 'CLEAR_CART'
  };
};

export function addForestToCart(forest) {
  return {
    type: 'ADD_FOREST_TO_CART',
    payload: forest,
  };
};

export function addSubscriptionToCart(subscription) {

  return {
    type: 'ADD_SUBSCRIPTION_TO_CART',
    payload: subscription,
  };
};

export function setPopupOpen(boolean) {

  return {
    type: 'SET_POPUP_OPEN',
    payload: boolean,
  };
};

export function setMiniPopupOpen(id) {

  return {
    type: 'SET_MINI_POPUP_OPEN',
    payload: id,
  };
};

export function rememberUserData(userData) {

  return {
    type: 'REMEMBER_USER_DATA',
    payload: userData,
  };
};

export function updateUserData(userData) {

  return {
    type: 'UPDATE_USER_DATA',
    payload: userData,
  };
};

export function setLang(lang) {

  return {
    type: 'SET_LANG',
    payload: lang,
  };
};

export function setIpCountryCode(form) {

  return {
    type: 'SET_IP_COUNTRY_CODE',
    payload: form,
  };
};


export function setWildlife(wildlife) {

  return {
    type: 'SET_WILDLIFE',
    payload: wildlife,
  };
};

export function setTrees(trees) {

  return {
    type: 'SET_TREES',
    payload: trees,
  };
};

export function setTreesObject(trees) {

  return {
    type: 'SET_TREES_OBJECT',
    payload: trees,
  };
};

export function setTreeOfMonth(tree) {

  return {
    type: 'SET_TREE_OF_MONTH',
    payload: tree,
  };
};

export function setPublicUserTrees(userTrees) {

  return {
    type: 'SET_PUBLIC_USER_TREES',
    payload: userTrees,
  };
};
