import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import { clearCart, rememberUserData } from '../../actions';

import LanguageToggle from './language_toggle'

import LeafLink from '../navigation/leaf_link';
import Svg from '../core/svg';

const SideMenu = ({ open }) => {
  const { t } = useTranslation(['components/nav']);
  const userData = useSelector(state => state.userData);
  const dispatch = useDispatch();
  let history = useHistory();
  const lang = useSelector(state => state.lang);

  const instagramLink = 'https://www.instagram.com/leafwildlife'
  const facebookLink = 'https://www.facebook.com/leafwildlife'
  const twitterLink = 'https://twitter.com/leafwildlife'

  useEffect(() => {
    if (open) {
      document.body.classList.add('side-panel-open');
    } else {
      document.body.classList.remove('side-panel-open');
    }
  }, [open])

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(
      clearCart()
    )
    dispatch(
      rememberUserData(false)
    );
    history.push(`/${lang}/login`);
  }


  return (
    <div className={`side-menu ${open ? "open-side-menu" : ""}`}>
      <div className={"side-menu-content flex column space-between"}>

        <div className={"absolute mt-20 visible-small"}>
          <LanguageToggle />
        </div>

        <div className={"nav-links flex column"}>
          {userData &&
            <LeafLink type="Nav" to="/profile" className={"pb-10"}>{t("components/nav:my_profile")}</LeafLink>
          }
          <LeafLink type="Nav" to="/">{t("components/nav:home")}</LeafLink>
          <LeafLink type="Nav" to="/wildlife">{t("components/nav:wildlife")}</LeafLink>
          <LeafLink type="Nav" to="/our-trees">{t("components/nav:our_trees")}</LeafLink>
          <LeafLink type="Nav" to="/about">{t("components/nav:about_us")}</LeafLink>
          <LeafLink type="Nav" to="/native-store">{t("components/nav:native_store")}</LeafLink>
          <LeafLink type="Nav" to="/our-projects">{t("components/nav:our_projects")}</LeafLink>
          <LeafLink type="Nav" to="/faqs">{t("components/nav:faqs")}</LeafLink>
          <LeafLink type="Nav" to="/contact">{t("components/nav:contact_us")}</LeafLink>
        </div>
        <div>
          <div className={"session-links flex column mt-20"}>
            {!userData ? (
              <>
                <LeafLink type="Nav" to="/login">{t("components/nav:log_in")}</LeafLink>
                <LeafLink type="Nav" to="/register">{t("components/nav:register")}</LeafLink>
              </>
            ) : (
              <LeafLink type="Nav" clickAction={handleLogout} to="/login">{t("components/nav:log_out")}</LeafLink>
            )}
          </div>
          <div className={"follow-us flex column align-center"}>
            <h2>{t("components/nav:follow_us")}</h2>
            <div className={"social-icons flex space-between"}>
              <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                <Svg icon={'facebook'} modifier={"side-menu__icon"} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" />
              </a>
              <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                <Svg icon={'instagram'} modifier={"side-menu__icon"} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" />
              </a>
              <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                <Svg icon={'twitter'} modifier={"side-menu__icon"} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
