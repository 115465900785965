
// token authorization
export const tokenAuthorization = {
  defaultAuthExpDays: 1,
  rememberMeLength: 14
}

// cookies
const totalDays = 14
const oneDay = 1000 * 60 * 60 * 24

export const cookiesConst = {        
  langExpires: new Date(Date.now() + (oneDay * totalDays)) 
}

// pagination
export const paginationConst = {        
    itemsPerPage: 8,
    paginationQuery: function(pagination){
      return pagination ? `?page=${pagination.currentPage}&records_per_page=${pagination.itemsPerPage}` : ''
    }
}

// animations
export const animationConst = {
    // Variants for animations - used on Row
    // allows staggered entrance of children
    // (i.e. appear one after another rather than all at once)
    container: {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        },
    },
    
    // Variants for animations - use on Col
    // Define entrance animation for children
    item: {
        hidden: {opacity: 0, x: -40},
        show: {opacity: 1, x: 0}
    },
    initial: {
      opacity: 0, 
      y: 100
    },
    animate: {
      opacity: 1, 
      y: 0
    },
    exit: {
      opacity:0, 
      y: 500, 
      transition: {duration: 0.8}
    },
    spring04: {
      duration: 0.4,
      type: "spring"
    },
    spring2: { 
      type: "spring", 
      duration: 2, 
      bounce: 0.6, 
      delay: 0.2
    }
}   

// user input validation
export const validation = {
  imageValidation: {
    avatar: {
      fileTypes: {
        condition: function(fileType){
          return !["image/png", "image/jpeg", "image/gif"].includes(fileType)
        },
        message: "wrong_file_type_use_png_jpg_jpeg_gif"
      },
      size: {
        condition: function(size){ 
          return size > 2048*1024
        },
        message: "max_file_size_is_2048kb"
      } 
    }
  } 
}

// user avalable languages
export const locales = [
  {
    "value": "en",
    "label": "English"
  },
  {
    "value": "es",
    "label": "Español"
  },
]

// public trees
export const publicTrees = {
  landingPageSlidesNo: {
    itemsPerPage: 10, 
    currentPage: 1
  }
}


