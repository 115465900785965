import axios from "axios";
import { loadState} from '../js/local_storage.js';

export class HttpClient {

  constructor(baseUrl, contentType = "application/json") {
    let headers = {
      "Content-Type": contentType
    }
  
    let userToken = loadState()?.userData.token
    let lang = loadState()?.lang
  
    if(userToken){
      headers['Authorization'] = 'Bearer ' + userToken
    }

    if(lang){
      headers['Content-Language'] = lang
    }

    this.client = axios.create({
      baseURL: baseUrl,
      headers
    });

    // this.setUpResponseInterceptor();
  }

  // setUpResponseInterceptor() {
  //   this.client.interceptors.response.use((config) => {
  //     return config.data;
  //   });
  // }

  async get(url, params = null) {
    return await this.client.get(url, params);
  }

  async post(url, body = null) {
    return await this.client.post(url, body);
  }

  async delete(url, body = null) {
    return await this.client.delete(url, body);
  }

  async put(url, body = null) {
    return await this.client.put(url, body);
  }
}
