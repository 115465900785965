const addSubscriptionToCartReducer = (state = {}, action) => {

  if (state === undefined) {
    return null;
  }

  if (action.type === 'ADD_SUBSCRIPTION_TO_CART') {
    const key = Object.keys(action.payload)[0]

    if (key === undefined || !key) {
      return state
    }

    if (action.payload[key].amount === 0 && key in state) {
      const newState = {...state};
      delete newState[key];

      return newState;
    }

    if (action.payload[key].amount === 0) {
      return state
    }

    return {...state, ...action.payload};
  } else {
    return state;
  }

}

export default addSubscriptionToCartReducer;
