const treesReducer = (state = false, action) => {
  if (action.type === 'SET_TREES') {
    return action.payload;
  } 
    
  return state;
  
}

export default treesReducer;
